import React from "react";
import ErrorBoundary from "./ErrorBoundary";
import styled from "styled-components";
import useTitle from "../hooks/useTitle";
import useFavicon from "../hooks/useFavicon";
import Board from "./Board";
import { reducer, init } from "../game/reducer";

// just for developing
import { State } from "../game/types";
import { createBoard } from "../game/helpers";
import { format } from "path";

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;
  justify-content: flex-start;
  background: var(--background);
`;

const TextLogo = styled.h1`
  margin: 0;
  height: 64rem;
  flex-basis: 0;
  padding-top: 12rem;
  font-family: Squada One;
  font-size: 28rem;
  letter-spacing: 0.4px;
  color: var(--typography);
`;

const initial = init();
const initialMoving: State = {
  ...createBoard([0, 1, 2, 14], [9, 21, 18, 10]),
  turns: 18,
  currentTurn: "white",
  hasCreatedMill: false,
};

function Game() {
  const [state, dispatch] = React.useReducer(reducer, initialMoving);
  const isPlacing = state.turns < 18;
  const isTaking = state.hasCreatedMill;

  useFavicon(state.currentTurn);
  useTitle(state.currentTurn);

  const onPlace = React.useCallback(
    (position) => {
      if (isPlacing && !isTaking) {
        dispatch({
          type: "place",
          position,
        });
      }
    },
    [dispatch, isPlacing, isTaking]
  );

  const onMove = React.useCallback((from, to) => {
    dispatch({
      type: "move",
      from,
      to,
    });
  }, []);

  const onTake = (position: number) => {
    if (isTaking && state.board[position] !== state.currentTurn) {
      // Without a timeout, a place event is fired on the appearing empty element 🤔
      setTimeout(() =>
        dispatch({
          type: "take",
          position,
        })
      );
    }
  };

  return (
    <Wrapper>
      <TextLogo>Mill</TextLogo>
      <ErrorBoundary>
        <Board
          pieces={state.board}
          currentTurn={state.currentTurn}
          isPlacing={isPlacing}
          isTaking={isTaking}
          onPlace={onPlace}
          onMove={onMove}
          onTake={onTake}
        />
      </ErrorBoundary>
    </Wrapper>
  );
}

export default Game;
