import styled from "styled-components";

type EmptyProps = {
  x: number;
  y: number;
  showHint: boolean;
  onClick: (event: React.SyntheticEvent) => void;
};

const Empty = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  left: ${(props: EmptyProps) => props.x - 16}px;
  top: ${(props: EmptyProps) => props.y - 16}px;
  background-color: ${(props: EmptyProps) =>
    props.showHint ? "#0000001a" : "transparent"};
  -webkit-tap-highlight-color: #00000000;
  transition-property: all;
  transition-duration: ${(props: EmptyProps) =>
    props.showHint ? "0ms" : "500ms"};
`;

export default Empty;
