import React from "react";
import { Player } from "../game/types";

const useFavicon = (theme: Player) => {
  const link = React.useRef(document.querySelector('link[rel="icon"]'));
  React.useEffect(() => {
    link.current?.setAttribute(
      "href",
      process.env.PUBLIC_URL + "/favicon_player_" + theme + ".png"
    );
  }, [theme]);
};

export default useFavicon;
