import React from "react";

class ErrorBoundary extends React.Component {
  //   constructor(props) {
  //     super(props);
  //     this.state = { hasError: false };
  //   }

  //   static getDerivedStateFromError(error) {
  //     // Update state so the next render will show the fallback UI.
  //     return { hasError: true };
  //   }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
