import React from "react";

const getViewPortSize = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
});

export default function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState(getViewPortSize);

  React.useEffect(() => {
    const handleResize = () => setWindowSize(getViewPortSize());
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}
