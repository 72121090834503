import React from "react";
import styled from "styled-components";
import { AnimatePresence } from "framer-motion";
import useWindowSize from "../hooks/useWindowSize";
import { Piece as PieceType, Player } from "../game/types";
import { POSITIONS, VALID_MOVES } from "../game/constants";
import { canMove } from "../game/reducer";
import Piece, { Coordinate } from "./Piece";
import Empty from "./Empty";

const AspectRatio = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
`;

const SVG = styled.svg`
  position: absolute;
  width: 100%;
  background-color: var(--board);

  rect,
  path {
    stroke: var(--lines);
    stroke-width: 5;
  }
`;

const generatePieceCoordinates = (
  padding: number,
  lineSpacing: number
): Coordinate[] => {
  return POSITIONS.map(([x, y]) => ({
    x: padding + x * lineSpacing,
    y: padding + y * lineSpacing,
  }));
};

type MemoizedBoardProps = { padding: number; lineSpacing: number };

const MemoizedBoard = React.memo(
  ({ padding, lineSpacing }: MemoizedBoardProps) => (
    <SVG viewBox="0 0 375 375">
      {[0, 1, 2].map((index) => (
        <rect
          key={index}
          x={padding + lineSpacing * index}
          y={padding + lineSpacing * index}
          width={(6 - index * 2) * lineSpacing}
          height={(6 - index * 2) * lineSpacing}
          fill="none"
        />
      ))}
      <path
        d={`M${padding + lineSpacing * 3} ${padding} L${
          padding + lineSpacing * 3
        } ${padding + lineSpacing * 2}`}
      />
      <path
        d={`M${padding + lineSpacing * 3} ${padding + lineSpacing * 4} L${
          padding + lineSpacing * 3
        } ${padding + lineSpacing * 6}`}
      />
      <path
        d={`M${padding} ${padding + lineSpacing * 3} L${
          padding + lineSpacing * 2
        } ${padding + lineSpacing * 3}`}
      />
      <path
        d={`M${padding + lineSpacing * 4} ${padding + lineSpacing * 3} L${
          padding + lineSpacing * 6
        } ${padding + lineSpacing * 3}`}
      />
    </SVG>
  )
);

type BoardProps = {
  pieces: PieceType[];
  currentTurn: Player;
  isPlacing: boolean;
  isTaking: boolean;
  onPlace: (position: number) => void;
  onTake: (position: number) => void;
  onMove: (from: number, to: number) => void;
};

export default function Board(props: BoardProps) {
  const PADDING = 28;
  const LINE_SPACING = 53;

  const viewport = useWindowSize();
  const scaleFactor = (1 / 375) * viewport.width;
  const coordinates = React.useMemo(
    () =>
      generatePieceCoordinates(
        PADDING * scaleFactor,
        LINE_SPACING * scaleFactor
      ),
    [scaleFactor]
  );

  const [showHint, setShowHint] = React.useState(false);
  const onBoardTap = React.useCallback(() => {
    if (props.isPlacing) {
      setShowHint(true);
      setTimeout(() => {
        setShowHint(false);
      }, 1000);
    }
  }, [props.isPlacing]);

  const validMoveCoordinates = props.pieces.map(
    (piece, index) =>
      piece === props.currentTurn &&
      VALID_MOVES[index]
        .filter((pos) => canMove(props.pieces, index, pos))
        .reduce(
          (acc, pos) => acc.set(pos, coordinates[pos]),
          new Map<number, Coordinate>()
        )
  );

  return (
    <AspectRatio onClick={onBoardTap}>
      <MemoizedBoard padding={PADDING} lineSpacing={LINE_SPACING} />
      {coordinates.map((coordinate, position) => {
        const colour = props.pieces[position];

        return (
          <AnimatePresence key={position}>
            {colour !== null ? (
              <Piece
                position={position}
                colour={colour}
                {...coordinate}
                scaleFactor={scaleFactor}
                isTaking={props.isTaking}
                validMoves={!props.isPlacing && validMoveCoordinates[position]}
                onClick={() => {
                  props.onTake(position);
                }}
                onMove={props.onMove}
              />
            ) : (
              <Empty
                key={position}
                {...coordinate}
                showHint={showHint}
                onClick={(event: React.SyntheticEvent) => {
                  event.stopPropagation();
                  props.onPlace(position);
                }}
              />
            )}
          </AnimatePresence>
        );
      })}
    </AspectRatio>
  );
}
