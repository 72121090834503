import { init } from "./reducer";
import { Board } from "./types";

// Should probably only be used for testing
export function createBoard(
  whitePieces: number[],
  blackPieces: number[],
  calculateTurns?: boolean
) {
  if (whitePieces.find((piece) => blackPieces.includes(piece))) {
    throw new Error("Duplicate piece");
  }

  const game: any = { board: init().board };
  for (const piece of whitePieces) {
    game.board[piece] = "white";
  }
  for (const piece of blackPieces) {
    game.board[piece] = "black";
  }

  if (calculateTurns) {
    game.turns = whitePieces.length + blackPieces.length;
  }

  return game;
}

// WIP, doesn't really work yet
export function logBoard(board: Board) {
  const black = "●";
  const white = "○";

  const layout = `$───────────$───────────$
|           |           |
|   $───────$───────$   |
|   |       |       |   |
|   |   $───$───$   |   |
|   |   |       |   |   |
├───$───$       $───$───$
|   |   |       |   |   |
|   |   $───$───$   |   |
|   |       |       |   |
|   $───────$───────$   |
|           |           |
$───────────$───────────$`;

  let i = -1;
  console.log(
    layout.replace(/\$/g, (r) => {
      i++;
      if (board[i] === null) {
        return r;
      }

      return board[i] === "white" ? white : black;
    })
  );
}
