import update from "immutability-helper";
import { NON_CONSECUTIVE_MILLS, VALID_MOVES } from "./constants";
import { State, Action, Board, Player, Piece } from "./types";

export function init(): Readonly<State> {
  const board = new Array(24).fill(null) as Board;

  return {
    board,
    turns: 0,
    currentTurn: "white",
    hasCreatedMill: false,
  };
}

export function reducer(state: State, action: Action): Readonly<State> {
  if (state.hasCreatedMill && action.type !== "take") {
    throw new Error(
      state.currentTurn +
        " must take a piece before performing an other action."
    );
  }

  switch (action.type) {
    case "place":
      if (state.board[action.position]) {
        throw new Error("Cannot place pieces on top of other pieces.");
      }

      if (state.turns >= 18) {
        throw new Error("Pieces can only be moved after placing phase.");
      }

      const boardWithPiece = placePiece(
        state,
        action.position,
        state.currentTurn
      );

      return checkForMills(boardWithPiece, action.position);

    case "take":
      if (
        state.board[action.position] === null ||
        state.currentTurn === state.board[action.position]
      ) {
        throw new Error("No piece at position or wrong colour.");
      }

      const opponentHasOnlyMills = state.board.every((piece, position) => {
        return (
          piece === null ||
          piece === state.currentTurn ||
          isPartOfMill(state.board, position)
        );
      });
      const pieceIsInMill = isPartOfMill(state.board, action.position);

      if (pieceIsInMill && !opponentHasOnlyMills) {
        throw new Error(
          "Cannot remove pieces from a mill unless player has only mills."
        );
      }

      const boardWithPieceTaken = placePiece(state, action.position, null);

      return update(boardWithPieceTaken, {
        turns: { $set: state.turns + 1 },
        currentTurn: {
          $set: otherPlayer(state.currentTurn),
        },
        hasCreatedMill: {
          $set: false,
        },
      });

    case "move":
      if (!canMove(state.board, action.from, action.to)) {
        throw new Error("Field is occupied or not adjacent.");
      }

      const pieceMoved = movePiece(state, action.from, action.to);
      return checkForMills(pieceMoved, action.to);

    case "jump":
      // @Todo
      // "After placing all pieces, they can be moved around.
      // "Pieces cannot jump on another piece.
      // "Upon closing mills, opponent's pieces can be taken.

      return state;

    default:
      throw new Error("Unknown action.");
  }
}

// Helpers
const otherPlayer = (player: Player): Player =>
  player === "white" ? "black" : "white";

// Reducers for partial tasks
const placePiece = (state: State, position: number, colour: Piece): State =>
  update(state, { board: { [position]: { $set: colour } } });

const movePiece = (state: State, from: number, to: number): State =>
  update(state, {
    board: {
      [to]: { $set: state.board[from] },
      [from]: { $set: null },
    },
  });

const checkForMills = (state: State, position: number): State => {
  const hasCreatedMill = isPartOfMill(state.board, position);

  return update(state, {
    hasCreatedMill: {
      $set: hasCreatedMill,
    },
    currentTurn: {
      $set: hasCreatedMill ? state.currentTurn : otherPlayer(state.currentTurn),
    },
    turns: { $set: hasCreatedMill ? state.turns : state.turns + 1 },
  });
};

// Checks
export const canMove = (board: Board, from: number, to: number): boolean =>
  VALID_MOVES[from].includes(to) && board[to] === null;

const isPartOfMill = (board: Board, position: number): boolean => {
  const pieceColour = board[position];
  const consecutiveCheckStart = position - (position % 3);
  const isConsecutiveMill =
    board[consecutiveCheckStart] === pieceColour &&
    board[consecutiveCheckStart + 1] === pieceColour &&
    board[consecutiveCheckStart + 2] === pieceColour;
  const nonConsecutiveMill = NON_CONSECUTIVE_MILLS.find((mill) =>
    mill.includes(position)
  );

  if (!nonConsecutiveMill) {
    return false;
  }

  const isNonConsecutiveMill =
    board[nonConsecutiveMill[0]] === pieceColour &&
    board[nonConsecutiveMill[1]] === pieceColour &&
    board[nonConsecutiveMill[2]] === pieceColour;

  return isConsecutiveMill || isNonConsecutiveMill;
};
