import React from "react";
import { Player } from "../game/types";

const useTitle = (currentTurn: Player) => {
  const PLAYER_COLOR = "white";
  React.useEffect(() => {
    document.title =
      currentTurn === PLAYER_COLOR ? "It’s your turn!" : "Opponent’s turn.";
  }, [currentTurn]);
};

export default useTitle;
