import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Player } from "../game/types";

const SIZE = 44;
const NEAR_THRESHOLD = 25;

type ScaleProps = { scaleFactor: number };
const Hitbox = styled(motion.div)`
  position: absolute;
  width: ${(props: ScaleProps) => props.scaleFactor * SIZE}px;
  height: ${(props: ScaleProps) => props.scaleFactor * SIZE}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-tap-highlight-color: #00000000;
`;

type CircleProps = ScaleProps & { colour: Player };
const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props: ScaleProps) => props.scaleFactor * 32}px;
  height: ${(props: ScaleProps) => props.scaleFactor * 32}px;
  border-radius: 50%;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  background: ${({ colour }: CircleProps) =>
    colour === "white" ? "var(--token-white)" : "var(--token-black)"};

  ::before {
    content: "";
    width: ${(props: ScaleProps) => props.scaleFactor * 20}px;
    height: ${(props: ScaleProps) => props.scaleFactor * 20}px;
    border-radius: 50%;
    background: inherit;
    transform: rotate(180deg);
    box-shadow: ${({ colour }: CircleProps) =>
      colour === "white"
        ? "var(--token-accent-white)"
        : "var(--token-accent-black)"};
  }
`;

// States
const flat = {
  scale: 1,
  zIndex: 0,
};
const taken = { scale: 1.5, opacity: 0 };
const elevated = { scale: 1.04 };
const flying = { scale: 1.35, zIndex: 1 };

export type Coordinate = { x: number; y: number };

const dragConstraints = (initial: Coordinate) => ({
  left: initial.x,
  top: initial.y,
  bottom: initial.y,
  right: initial.x,
});

const distance = (a: Coordinate, b: Coordinate) =>
  Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2);

type PieceProps = Coordinate & {
  position: number;
  colour: Player;
  scaleFactor: number;
  isTaking: boolean;
  validMoves: Map<number, Coordinate> | false;
  onClick: () => void;
  onMove: (from: number, to: number) => void;
};

function Piece(props: PieceProps) {
  const size = SIZE * props.scaleFactor;
  const canMove = Boolean(props.validMoves && props.validMoves.size);
  const initial = {
    ...(canMove ? flat : flying),
    x: props.x - size / 2,
    y: props.y - size / 2,
  };

  const [isHover, setIsHover] = React.useState(false);
  const [isDragging, setIsDragging] = React.useState(false);
  const [dropPosition, setDropPosition] = React.useState<number>(
    props.position
  );

  const state = isDragging ? flying : isHover && canMove ? elevated : flat;

  const onHover = (event: MouseEvent) =>
    setIsHover(event.type === "pointerenter");

  const onDrag = React.useCallback(
    (_, info) => {
      // @ts-ignore
      for (let [position, coordinate] of (props.validMoves as Map<
        number,
        Coordinate
      >).entries()) {
        const targetCoordinate = {
          x: info.point.x + SIZE / 2,
          y: info.point.y + SIZE / 2,
        };
        const near = distance(coordinate, targetCoordinate) <= NEAR_THRESHOLD;

        if (position !== dropPosition) {
          setDropPosition(near ? position : props.position);
        }

        if (near) {
          break;
        }
      }
    },
    [props.validMoves, props.position, dropPosition]
  );

  return (
    <Hitbox
      scaleFactor={props.scaleFactor}
      initial={initial}
      animate={state}
      exit={props.isTaking ? taken : {}}
      onHoverStart={onHover}
      onHoverEnd={onHover}
      drag={canMove}
      dragConstraints={dragConstraints(initial)}
      dragElastic={1}
      onDragStart={() => setIsDragging(true)}
      onDrag={onDrag}
      onDragEnd={() => {
        const hasMoved = dropPosition && dropPosition !== props.position;
        if (hasMoved) {
          props.onMove(props.position, dropPosition);
        } else {
          setIsDragging(false);
        }
      }}
    >
      {/* Click target has to be not a motion div for stopPropagation to work */}
      <div
        onClick={(event) => {
          if (!isDragging) {
            event.stopPropagation();
            props.onClick();
          }
        }}
      >
        <Circle colour={props.colour} scaleFactor={props.scaleFactor} />
      </div>
    </Hitbox>
  );
}

export default React.memo(Piece);
